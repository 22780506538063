<template>
    <div>
        <v-row>
            <v-col cols="12" md="12">
                <v-card class="mx-auto fill-height" min-height="280" outlined>
                    <v-card-title>
                        Bestellverlauf SE-Sales
                    </v-card-title>
                    <v-data-table :headers="headers" :items="$store.state.bestellungen.allBestellungenArray"
                        :items-per-page="20" :search="search" group-by="vertrag" show-group-by class="elevation-1">
                        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                            v-slot:[`item.${header.value}`]="{ value }">
                            {{ header.formatter(value) }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                                <v-icon v-if="item.nextStep != 'abgeschlossen' && item.nextStep != 'bestellt'" small class="mr-2" @click="nudgeOrder(item.id)">
                                    mdi-redo-variant
                                </v-icon>
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <!--<v-row>
        <pre>{{
          JSON.stringify($store.state.contract.aktuellerVertrag, null, 2)
        }}</pre>
      </v-row>-->
    </div>
</template>
  
<script>

export default {
    components: {
    },
    data() {
        return {
            search: "",
            headers: [
                { text: "Erstellt", value: "created_ts", formatter: this.getDateFromTS },
                {
                    text: "Vertrag",
                    align: "start",
                    value: "vertrag"
                },
                { text: "Tranche", value: "tranchenname" },
                { text: "Schritt", value: "nextStep" },
                { text: "Status", value: "status" },
                { text: "Preise (ct/kWh)", value: "preis",formatter: this.showPreis, groupable: false, },
                { text: "#", value: "TrackingCounter", groupable: false, },
                { text: "letzte Meldung", value: "updated_ts", formatter: this.getDateFromTS, groupable: false, },
                { text: "Actions", value: "actions", sortable: false,groupable: false }
            ]
        }
    },
    methods: {
        showPreis(preisobj) {
            return `Base: ${preisobj.base} Peak: ${preisobj.peak} Offset: ${preisobj.struct}`
        },
        getDateFromTS(ts) {
            let timestamp = false
            switch (typeof (ts)) {
                case "object":
                    timestamp = ts.seconds*1000
                    break;
                case "number":
                    timestamp = parseInt(ts) * 1000
                    break;
            }
            const dateobj = (timestamp) ? new Date(timestamp) : new Date(ts)
            return dateobj.toLocaleString("de-DE")
        },
        germanNumberFormat(number) {
            if (typeof (number) === "string") {
                number = parseFloat(number)
            }
            return number.toLocaleString("de-DE")
        },
        nudgeOrder(id) {
            console.log(id)
            this.$store.dispatch("nudgeBestellung", { bestellid: id })
        }
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
        this.$store
            .dispatch("getAllBestellungen")
            .then(() => {
                console.log("fertisch")

            })
    }
}
</script>
  
<style></style>