var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "mx-auto fill-height",
                  attrs: { "min-height": "280", outlined: "" },
                },
                [
                  _c("v-card-title", [_vm._v(" Bestellverlauf SE-Sales ")]),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.$store.state.bestellungen.allBestellungenArray,
                      "items-per-page": 20,
                      search: _vm.search,
                      "group-by": "vertrag",
                      "show-group-by": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(
                          _vm.headers.filter((header) =>
                            header.hasOwnProperty("formatter")
                          ),
                          function (header) {
                            return {
                              key: `item.${header.value}`,
                              fn: function ({ value }) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(header.formatter(value)) + " "
                                  ),
                                ]
                              },
                            }
                          }
                        ),
                        {
                          key: "item.actions",
                          fn: function ({ item }) {
                            return [
                              item.nextStep != "abgeschlossen" &&
                              item.nextStep != "bestellt"
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.nudgeOrder(item.id)
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-redo-variant ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }